
.login-container {
  height: 100vh;
  background-color: #4676A9;
  background-image: linear-gradient(to top, #3b587f, #3e6d95, #3f82aa, #4198be, #44afd0);
  background-repeat: no-repeat;
  background-position: bottom;

  .login-container-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    height: 100%;
  }

  .login-form-container {
    position: relative;
    width: 50%;
    height: 50%;
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    padding-top: 120px;

    @media screen and (max-width: 850px) {
      width: 80%;
      height: 70%;
    }

    .logo {
      width: 50%;
      max-width: 200px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    h3 {
      font-weight: bold;
      font-size: 26px;
    }

    .login-form {
      width: 80%;
      margin-left: 10%;

      input {
        padding: 10px;
        border-radius: 10px;
        outline: none;
        font-size: 20px;
        
      }

      input[type=submit] {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}