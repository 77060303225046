$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .m-#{str-slice($side, 0, 1)}-#{$space}p {
      margin-#{$side}: #{$space}% !important;
    }
  
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space}p {
      padding-#{$side}: #{$space}% !important;
    }
  }
}

html, body {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #707070;
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex1 {
  flex: 1;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}

.shadow {
  -webkit-box-shadow: 2px 2px 20px -12px rgba(0,0,0,0.1);
  -moz-box-shadow: 2px 2px 20px -12px rgba(0,0,0,0.1);
  box-shadow: 2px 2px 20px -12px rgba(0,0,0,0.1);
}

.heading {
  font-size: 26px;
  color: black;
  font-weight: 600;
}

.list-item-row {
  padding: 10px;
  padding-left: 30px;
  border-top: 1px solid #B4B4B4;
  margin-right: 30px;

  color: #707070;
  font-weight: bold;
}

table {
  overflow-x: scroll;
}

.department-name {
  text-transform: capitalize;
}

.group-nav {
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: none;
  }
}

.print-none {
  @media print {
    display: none;
  }
}
.print-only {
  display: none;
  @media print {
    display: block;
  }
}
@media print
{
  table { page-break-after:auto; height: fit-content; }
  tr    { page-break-inside:avoid; page-break-after:auto }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  tfoot { display:table-footer-group }
  th div, td div {
    margin-top:-8px;
    padding-top:8px;
    page-break-inside:avoid;
  }
}