.ra-button {
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  padding: 10px 20px;

  &.blue {
    background-color: #44B0D1;
    color: white;
  }

  &.green {
    background-color: #008400;
    color: white;
  }

  &.grey {
    background-color: #A7A7A7;
    color: white;
  }

  &.small {
    padding: 5px 10px;
  }
}