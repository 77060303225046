

.list-tracking-container {
  height: 50vh;
  overflow-y: auto;

  table th {
    font-size: 12px;
    // text-align: center;
  }

  .track-user {
    font-weight: 600;
    margin-top: 20px;

    td:nth-child(2) {
      text-align: right;
    }
  }
}