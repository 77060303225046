.sidebar-container {
  height: 100vh;
  width: 250px;
}

.sidebar-container, .sidebar-container-slide-out {
  background-image: linear-gradient(to top, #3b587f, #3e6d95, #3f82aa, #4198be, #44afd0);
  outline: none;

  .logo-sidebar {
    width: 50%;
    height: auto;
    margin-left: 25%;
    margin-top: 20px;
  }

  .menu-list {
    padding: 20px;
    margin-top: 20px;
    
    .menu-item {
      cursor: pointer;
      margin-bottom: 20px;
      color: white;
      font-size: 16px;
      font-weight: 600;

      a {
        color: white;
        text-decoration: none;
      }

      &.active {
        text-decoration: underline;
      }
    }
  }
}

.sidebar-container-slide-out {
  background-image: none;

  .menu-list .menu-item {
    color: #5079AD;

    a {
      color: #5079AD;
    }
  }
}

.sidebar-container-dummy-burger {
  width: 100%;
  height: 70px;
  background-color: #44afd0;
}