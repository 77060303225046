.ra-input {
  outline: none;
  padding: 7px 10px;
  border-radius: 5px;
  border: 1px solid #BFBFBF;
  font-size: 15px;

  &:disabled {
    background-color: #f5f5f5;
  }
}