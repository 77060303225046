.td-group-name {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}

.group-value {
  font-weight: 400;
}

.category-heading-container {
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  text-transform: capitalize;

  &.active {
    color: #57BDD8;
  }
}