.compliance-item {
  font-weight: 400;
}

.compliance-searchbox {

  display: flex;
  align-items: center;

  .search-heading {
    font-weight: bold;
    margin-right: 20px;
  }

  .input-search {
    width: 250px;
    position: relative;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 20px;
  }
}