.app-container {
  height: 100vh;

  .content-container {
    overflow-y: auto;
    // overflow-x: hidden;
  }

  @media screen and (max-width: 775px) {
    margin: 0px;
    padding: 0px;
    width: 100vw;
    flex-direction: column;
  }
}