.ra-form-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  .ra-form-container-row {
    width: 100%;
  }

  .ra-form-container-inner {
    margin-right: auto;
    margin-left: auto;
    
  }
}