.span-btn {
  color: #44B0D1;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
  display: inline-block;

  &.active {
    text-decoration: underline;
  }
}

.contact-searchbox {
  margin-left: 10px;
  display: flex;
  align-items: center;

  .search-heading {
    font-weight: bold;
    margin-right: 20px;
  }

  .input-search {
    width: 250px;
    position: relative;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 20px;
    margin-left: 0px;
  }
}

.group-title-container {
  @media screen and (max-width: 775px) {
    flex-direction: column;
    text-align: center;
  }
}