
.notes {
  &__wrapper {
    
  }

  &__modals__container {
    width: 100%;
  }

  &__img {
    height: 41px;
    width: auto;
    cursor: pointer;
  }

  &__modal {
    width: 100%;
    padding: 15px;
    min-height: 100px;

    &__title {
      font-weight: bold;
      color: black;
    }

    &__list-container {
      height: 350px;
      overflow-y: auto;
      margin-bottom: 20px;
    }
  }

  &__crud {
    textarea {
      border: 1px solid #d1d1d1;
      height: 100px;
      width: 100%;
      padding: 10px;
      border-radius: 10px;
    }

    &__upload-images {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      img {
        width: 100px;
        height: 50px;
        object-fit: cover;
        border-radius: 10px;
        border: 1px solid #d1d1d1;
        margin-right: 10px;
        margin-bottom: 10px;

        &.remove-img {
          position: absolute;
          top: -4px;
          right: -3px;
          width: 15px;
          height: 15px;
          background-color: white;
          cursor: pointer;
        }

        &.add {
          object-fit: contain;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }

  &__single {
    display: flex;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-right: 10px;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 10px;
    
    .content {
      flex: 1;

      .content-images {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        img {
          width: 100px;
          height: 50px;
          object-fit: cover;
          border-radius: 10px;
          border: 1px solid #d1d1d1;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
      
    }
    .timestamp {
      font-weight: 300;
      text-align: right;
    }
  }
  
}