.ra-form-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding-top: 150px;
    background-image: none !important;
  }

  .ra-form-container-row {
    width: 100%;
  }

  .ra-form-container-inner {
    margin-right: auto;
    margin-left: auto;
  }

  .invite-logo-container {
    position: absolute;
    top: 20px;
    left: 40px;
    img {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 480px) {
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100px;
        height: 100px;
      }
    }
  } 
}

